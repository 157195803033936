var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isDataLoaded)?_c('div',{staticClass:"p-4 bg-white rounded-lg"},[_c('DataTable',{ref:"datatable",attrs:{"table_state":_vm.table_state,"store_names":_vm.store_names,"addComponent":_vm.componentForAdding,"editComponent":_vm.componentForEditing,"delete_url":_vm.delete_url,"actionsComponent":null,"urls":_vm.urls},scopedSlots:_vm._u([{key:"datatableTop",fn:function(data){return [(_vm.permissionChecker('print'))?_c('Generate',{attrs:{"items":data.selected,"types":_vm.types}}):_vm._e()]}},{key:"rowActions",fn:function({ item, permissions }){return [_c('v-menu',{attrs:{"left":"","transition":"scale-transition","offset-y":"","bottom":"","rounded":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('a',_vm._g(_vm._b({staticClass:"btn btn-light-light btn-hover-info show poppins min-w-100px min-w-md-110px"},'a',attrs,false),on),[_vm._v(" Actions "),_c('span',{staticClass:"svg-icon svg-icon-5 m-0"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-chevron-down")])],1)])]}}],null,true)},[_c('v-card',{staticClass:"poppins py-1",attrs:{"elevation":"1"}},[(permissions('view'))?_c('div',[_c('v-btn',{staticClass:"w-100 text-left",class:!item.extra_data.actions.view ? 'text-muted' : 'text-dark',attrs:{"disabled":!item.extra_data.actions.view,"text":""},on:{"click":() => {
                  _vm.$refs.datatable.activateView(item);
                }}},[_vm._v("View ")])],1):_vm._e(),(permissions('update'))?_c('div',[_c('v-btn',{staticClass:"w-100 text-left",class:!item.extra_data.actions.edit ? 'text-muted' : 'text-dark',attrs:{"disabled":!item.extra_data.actions.edit,"text":""},on:{"click":() => {
                  _vm.actionEdit(item);
                }}},[_vm._v("Edit ")])],1):_vm._e(),(permissions('update'))?_c('div',[_c('v-btn',{staticClass:"w-100 text-left",class:!item.extra_data.actions.confirm ? 'text-muted' : 'text-dark',attrs:{"disabled":!item.extra_data.actions.confirm,"text":""},on:{"click":() => {
                  _vm.$refs.datatable.statusActionNew(item, null);
                }}},[_vm._v("Approve ")])],1):_vm._e(),(permissions('destroy'))?_c('div',[_c('v-btn',{staticClass:"w-100 text-left",class:!item.extra_data.actions.remove ? 'text-muted' : 'text-dark',attrs:{"disabled":!item.extra_data.actions.remove,"text":""},on:{"click":() => {
                  _vm.$refs.datatable.removeItem(item);
                }}},[_vm._v("Delete ")])],1):_vm._e()])],1)]}}],null,false,3164320125)})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }