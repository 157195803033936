<template>
  <div>
    <v-dialog
      v-model="stage1"
      persistent
      transition="dialog-bottom-transition"
      max-width="600"
      v-if="serverDataLoaded"
    >
      <div class="bg-white poppins">
        <div class="modal-header py-4 align-center">
          <h4 class="mb-0 font-weight-bolder">Create invoice</h4>
          <div
            class="btn btn-sm btn-icon btn-active-light-info"
            @click="toggleModal"
          >
            <span class="svg-icon">
              <v-icon size="22">mdi-close</v-icon>
            </span>
          </div>
        </div>
        <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
          <!--begin::Body-->
          <form id="createForm" class="pa-3 pb-0">
            <v-row>
              <div class="col-12 my-0 py-0">
                <v-select
                  v-model="formData.contract_id"
                  label="Contract"
                  item-text="text"
                  item-value="index"
                  :items="contracts"
                  clearable
                  outlined
                  :error-messages="contract_idErrors"
                  @input="$v.formData.contract_id.$touch()"
                  @blur="$v.formData.contract_id.$touch()"
                ></v-select>
              </div>
              <div class="col-12 my-0 py-0">
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="formData.date_range"
                      label="Date range"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      outlined
                      v-bind="attrs"
                      v-on="on"
                      :error-messages="date_rangeErrors"
                      @input="$v.formData.date_range.$touch()"
                      @blur="$v.formData.date_range.$touch()"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="formData.date_range"
                    @change="menu2 = false"
                    range
                  ></v-date-picker>
                </v-menu>
              </div>
            </v-row>
          </form>
          <!--end::Body-->
          <div class="my-4 d-flex flex-grow-1 align-end justify-center">
            <button
              type="reset"
              class="btn btn-light mr-3 px-5 py-3 ls1"
              @click="resetCreateForm"
            >
              Clear
            </button>
            <button
              type="submit"
              class="btn btn-info px-5 py-3 ls1"
              @click="submitCreateForm"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </v-dialog>
    <AddItem2
      ref="addItem2"
      :refresher="refresher"
      :pageLoader="pageLoader"
    ></AddItem2>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import ApiService from "@/core/services/api.service";
import AddItem2 from "@/own/components/finance/invoices/AddItem2";

export default {
  mixins: [validationMixin],
  validations: {
    formData: {
      contract_id: { required },
      date_range: { required },
    },
  },
  name: "AddItem",
  props: ["refresher", "pageLoader"],
  components: { AddItem2 },
  data: () => ({
    stage1: false,
    menu2: false,
    serverData: null,
    formData: {
      contract_id: null,
      date_range: [],
    },
  }),
  methods: {
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;

      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },

    toggleModal() {
      this.stage1 = !this.stage1;
      // console.log(this.clients);
    },
    validateData() {
      return true;
    },
    convertToFormData() {
      let data = this.formData;

      return data;
    },
    submitCreateForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        if (this.validateData()) {
          this.pageLoader(true);
          // let data = JSON.stringify(this.entCreate);
          let data = this.convertToFormData();
          // console.log("data to send is ", data);
          ApiService.post("/cod/invoices/create", data)
            .then((responses) => {
              Swal.fire({
                title: "Created",
                text: `Invoice has been successfully added!`,
                icon: "success",
                showConfirmButton: false,
                timer: 2500,
              });
              this.serverData = responses.data;
              this.resetCreateForm();
              this.toggleModal();
              this.refresher();
              this.$refs.addItem2.loadServerData({ ...this.serverData });
              this.$refs.addItem2.toggleModal();
            })
            .catch(() => {
              this.pageLoader(false);
            });
        } else {
          return null;
        }
      }
    },
    resetCreateForm() {
      this.$v.$reset();
      this.formData = {
        contract_id: null,
        date_range: [],
      };
    },
  },
  computed: {
    contracts: function () {
      return this.$store.getters.getINVOICESTableStructure.contracts;
    },
    serverDataLoaded: function () {
      return !!this.contracts;
    },
    contract_idErrors: function () {
      return this.handleFormValidation("contract_id", this);
    },
    date_rangeErrors: function () {
      return this.handleFormValidation("date_range", this);
    },
  },
};
</script>
