<template>
  <div class="p-4 bg-white rounded-lg" v-if="isDataLoaded">
    <DataTable
      :table_state="table_state"
      :store_names="store_names"
      :addComponent="componentForAdding"
      :editComponent="componentForEditing"
      :delete_url="delete_url"
      :actionsComponent="null"
      :urls="urls"
      ref="datatable"
    >
      <template #datatableTop="data">
        <Generate
          :items="data.selected"
          :types="types"
          v-if="permissionChecker('print')"
        ></Generate>
      </template>
      <template v-slot:rowActions="{ item, permissions }">
        <v-menu left transition="scale-transition" offset-y bottom rounded>
          <template v-slot:activator="{ on, attrs }">
            <a
              v-bind="attrs"
              v-on="on"
              class="btn btn-light-light btn-hover-info show poppins min-w-100px min-w-md-110px"
            >
              Actions
              <span class="svg-icon svg-icon-5 m-0">
                <v-icon small>mdi-chevron-down</v-icon>
              </span>
            </a>
          </template>
          <v-card class="poppins py-1" elevation="1">
            <div v-if="permissions('view')">
              <v-btn
                class="w-100 text-left"
                :disabled="!item.extra_data.actions.view"
                :class="
                  !item.extra_data.actions.view ? 'text-muted' : 'text-dark'
                "
                @click="
                  () => {
                    $refs.datatable.activateView(item);
                  }
                "
                text
                >View
              </v-btn>
            </div>
            <div v-if="permissions('update')">
              <v-btn
                class="w-100 text-left"
                :disabled="!item.extra_data.actions.edit"
                :class="
                  !item.extra_data.actions.edit ? 'text-muted' : 'text-dark'
                "
                @click="
                  () => {
                    actionEdit(item);
                  }
                "
                text
                >Edit
              </v-btn>
            </div>
            <div v-if="permissions('update')">
              <v-btn
                class="w-100 text-left"
                :disabled="!item.extra_data.actions.confirm"
                :class="
                  !item.extra_data.actions.confirm ? 'text-muted' : 'text-dark'
                "
                @click="
                  () => {
                    $refs.datatable.statusActionNew(item, null);
                  }
                "
                text
                >Approve
              </v-btn>
            </div>
            <div v-if="permissions('destroy')">
              <v-btn
                class="w-100 text-left"
                :disabled="!item.extra_data.actions.remove"
                :class="
                  !item.extra_data.actions.remove ? 'text-muted' : 'text-dark'
                "
                @click="
                  () => {
                    $refs.datatable.removeItem(item);
                  }
                "
                text
                >Delete
              </v-btn>
            </div>
          </v-card>
        </v-menu>
      </template>
    </DataTable>
  </div>
</template>

<script>
import DataTable from "@/own/components/DataTable.vue";
import AddItem from "@/own/components/finance/invoices/AddItem.vue";
import EditItem from "@/own/components/finance/invoices/EditItem";
import Generate from "@/own/components/finance/invoices/Generate.vue";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import { SET_ITEM_FOR_ACTION } from "@/core/services/store/main.module";
import {
  EXPORT_TABLE_DATA,
  SET_TABLE_FILTER,
  SET_TABLE_PAGE,
  SET_TABLE_PERPAGE,
  SET_TABLE_SORTBY,
  UPDATE_TABLE_DATA,
  UPDATE_TABLE_STRUCTURE,
} from "@/core/services/store/invoices.module";
export default {
  name: "Invoices",
  components: { DataTable, Generate },
  data: () => ({
    componentForEditing: EditItem,
    componentForAdding: AddItem,
    delete_url: `${process.env.VUE_APP_BASE_URL}/cod/invoices/destroy`,
    table_state: {
      UPDATE_TABLE_STRUCTURE: UPDATE_TABLE_STRUCTURE,
      UPDATE_TABLE_DATA: UPDATE_TABLE_DATA,
      SET_TABLE_SORTBY: SET_TABLE_SORTBY,
      SET_TABLE_FILTER: SET_TABLE_FILTER,
      SET_TABLE_PERPAGE: SET_TABLE_PERPAGE,
      SET_TABLE_PAGE: SET_TABLE_PAGE,
      EXPORT_TABLE_DATA: EXPORT_TABLE_DATA,
    },
    store_names: {
      getTableFilters: "getINVOICESTableFilters",
      getTableState: "getINVOICESTableState",
      getTableData: "getINVOICESTableData",
      getTableHeaders: "getINVOICESTableHeaders",
      getTableProperties: "getINVOICESTableProperties",
      getTableExportUrl: "getINVOICESExportUrl",
      getTablePermissions: "getINVOICESTablePermissions",
    },
    urls: {
      status: `/cod/invoices/approve_invoice`,
    },
  }),
  methods: {
    permissionChecker(permType) {
      // console.log(this.store_names.getTablePermissions, permType);
      let result = false;
      this.$store.getters[this.store_names.getTablePermissions].forEach(
        (element) => {
          if (element.name == permType) {
            result = true;
          }
        }
      );
      return result;
    },
    async initTableContent() {
      await this.$store.dispatch(this.table_state.UPDATE_TABLE_STRUCTURE, {});
      await this.$store.dispatch(this.table_state.UPDATE_TABLE_DATA, {});
    },
    actionEdit(item) {
      this.$store.commit(SET_ITEM_FOR_ACTION, item);
      this.$refs.datatable.$refs.editModal.toggleModal();
    },
  },
  computed: {
    isDataLoaded: function () {
      return this.$store.getters[this.store_names.getTableData];
    },
    types: function () {
      let typies = [
        // { name: "shipping label", value: "label" },
        { name: "barcode", value: "barcode" },
        // { name: "invoice", value: "invoice" },
        // { name: "manifest", value: "manifest" },
        // { name: "packing summary", value: "packing_summary" },
        // { name: "packing slip", value: "packing_slip" }
      ];
      return typies;
      // return this.$store.getters.getORDERSPrintTypes;
    },
  },
  beforeMount() {
    this.$store.commit(SET_PAGE_LOADING, true);
    this.initTableContent();
  },
};
</script>
